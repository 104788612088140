import React, { useEffect, useState } from "react";
import Assesment from "components/UI/assessment";
import { makeSelectSelectedInduction } from "store/induction/selector";
import { makeSelectAssesmentState } from "store/assesment/selector";
import { setAssesmentState } from "store/assesment/actions";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import { Link } from "react-router-dom";
import { useLocate, saveProgress } from "utils/hooks";
import classes from "./assessment.module.scss";
import { isNil } from "lodash";

const AssesmentPage = ({
  selectedInduction,
  history,
  assesmentState,
  setAssesmentStateReq,
  isPreview=false,
  pageData
}) => {
  var { next, current } = useLocate(
    selectedInduction,
    history?.location.pathname
  );

  if(isPreview)
  {
    current = pageData;
  }

  useEffect(() => {
    if(!isPreview)
       saveProgress(selectedInduction, history.location.pathname, 0);
  }, []);

  if (isNil(current?.data?.assessmentId)) {
    return null;
  }

  return (
    <>
      <div className={classes.container}>
        <Assesment
          interactive={true}
          assessmentId={current?.data?.assessmentId}
          isPreview={isPreview}
        />
      </div>
      {isNil(assesmentState?.completed) && !isNil(assesmentState?.visible) && !isPreview ? (
        <Footer>
          <Button
            subText={""}
            type={""}
            theme={"blue"}
            id={"sendButton"}
            // className={classes.sendButton}
            text={
              assesmentState?.buttonText
                ? assesmentState.buttonText
                : "Submit Your Answer"
            }
            formatID={
              assesmentState?.buttonTextId
                ? assesmentState.buttonTextId
                : "assesment.button.submit.answer"
            }
            onClick={() => {
              setAssesmentStateReq({
                ...assesmentState,
                trigger: true,
              });
            }}
          />
        </Footer>
      ) : (
        assesmentState?.completed === true && !isPreview && (
          <>
            <Footer>
              <Button
                onClick={() => {
                  saveProgress(selectedInduction, history.location.pathname);

                  history.push(next?.url);
                }}
                id="apply-button"
                text="Next"
                type="submit"
                theme="blue"
                formatID={"next.button"}
              />
            </Footer>
          </>
        )
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedInduction: makeSelectSelectedInduction(),
  assesmentState: makeSelectAssesmentState(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    setAssesmentStateReq: (val) => dispatch(setAssesmentState(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssesmentPage);
