import React,{useState,useEffect} from "react";
import Input from "../../../../../components/Supervisor/input";
import ImageUpload from "../../../Hazards/components/imageUpload";
import classes from "../editor.module.scss";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
    uploadInductionImageReq
  } from "store/supervisor/actions";
  
import {
    makeSelectInductionImage,
    makeSelectLoading,
    makeSelectInductionImageLoading,
    makeSelectInductionImageError,
    makeSelectInductionImageSuccess,
} from "store/supervisor/selector";


const ChaptersForm = ({
page,
updatePageData, 
chapter, 
section,
inductionImage,
inductionImageLoading,
inductionImageSuccess,
inductionImageError,
uploadInductionImage,
setSelectedSection,
translations,
selectedLanguage,
updateTranslation,
setEditData

}) => {


const [rules, setRules] = useState(page?.data?.rules);
const [initialState, setInitialState] = useState(true);
const [selectedComponent, setSelectedComponent] = useState(0);
const [selectedKey, setSelectedKey] = useState('image_id');
const [selectedCard, setSelectedCard] = useState(0);

const updateRule = (item, value) => {
    var updatedRules = [...rules];
    updatedRules[item] = {...rules[item], content:value};
    setRules(updatedRules);
    updatePageData({key:'rules',value:updatedRules});
}

useEffect(() => 
{
    if(initialState)
       return;

    if (inductionImage?.url) 
    {
       setEditData(true);
       
       var newComponentUpdate = [ ...section.data.components ]
                            
       for(var j = 0; j < newComponentUpdate.length; j++)
       {
           if(j == selectedComponent)
           {
              if(newComponentUpdate[j]?.type == 'flipcard')
              {
                if(selectedKey == 'front_image')
                { 
                    var cardsUpdate = [ ...newComponentUpdate[j].cards];
                    cardsUpdate[selectedCard] = {...cardsUpdate[selectedCard], 
                                                 front_image_id:inductionImage?.id,
                                                 front_image: inductionImage?.url
                                                };

                    newComponentUpdate[j] = {...newComponentUpdate[j],cards: cardsUpdate};
                }
                else if(selectedKey == 'back_image')
                { 
                    var cardsUpdate = [ ...newComponentUpdate[j].cards];
                    cardsUpdate[selectedCard] = {...cardsUpdate[selectedCard], 
                                                    back_image_id:inductionImage?.id,
                                                    back_image: inductionImage?.url
                                                };

                    newComponentUpdate[j] = {...newComponentUpdate[j],cards: cardsUpdate};
                }
              }
              else if(newComponentUpdate[j]?.type == 'carousel')
              {
                  if(selectedKey == 'image')
                  { 
                      var cardsUpdate = [ ...newComponentUpdate[j].content];
                      cardsUpdate[selectedCard] = {...cardsUpdate[selectedCard], 
                                                   image_id:inductionImage?.id,
                                                   image: inductionImage?.url
                                                  };
  
                      newComponentUpdate[j] = {...newComponentUpdate[j],content: cardsUpdate};
                  }
              }
              else if(newComponentUpdate[j]?.type == 'hotspots')
              {
                 //hotspot image use the key 'background' so it's different
                 newComponentUpdate[j] = {...newComponentUpdate[j],background_id: inductionImage?.id, background: inductionImage?.url};
              }
              else
                 newComponentUpdate[j] = {...newComponentUpdate[j],image_id: inductionImage?.id, image: inductionImage?.url};
           }
       }
       
       var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
       setSelectedSection(updatedData);

    }
  }, [inductionImage]);

  //console.log('components',section?.data?.components);

  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Blocks List</h4>
        
        {section?.data?.components.map((item,index) =>
                       
            <>           
            <Input
                hasTooltip={true}
                order="column"
                value={item?.title_id ? translations[selectedLanguage.code][item?.title_id] ? translations[selectedLanguage.code][item?.title_id] : '' : ''}
                name={"component_title_"+index}
                characterLimit={120}
                onChange={(e) => {
                    var newComponentUpdate = [ ...section.data.components ]
                            
                    for(var j = 0; j < newComponentUpdate.length; j++)
                    {
                        if(j == index)
                            newComponentUpdate[j] = {...newComponentUpdate[j],title:e.target.value};

                    }
                    
                    var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                    setSelectedSection(updatedData);
                    

                    //update translation
                    updateTranslation(selectedLanguage.code,item?.title_id,e.target.value);
                }}
                label={"Title"}
                placeholder={"Title here"}
                error={""}
                tooltipText={
                "Title text"
                }
            />
            <br />
            
            {item?.type == 'image_text_full' && (
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={item?.image}
                        description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />
            )}

            {item?.type == 'image_text_regular' && (
                <div>
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={item?.image}
                        description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {

                            setInitialState(false);
                            setSelectedComponent(index);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                        }
                        }}
                    />

                    <Input
                        hasTooltip={true}
                        order="column"
                        value={item?.header_id ? translations[selectedLanguage.code][item?.header_id] ? translations[selectedLanguage.code][item?.header_id] : '' : ''}
                        name={item?.header_id ? item?.header_id : "component_header_"+index}
                        characterLimit={120}
                        onChange={(e) => {
                            /*
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],header:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                           */

                           //update translation
                           updateTranslation(selectedLanguage.code,item?.header_id,e.target.value);
                        }}
                        label={"Header"}
                        placeholder={"header here"}
                        error={""}
                        tooltipText={
                        "Header text"
                        }
                    />


                    <Input
                        hasTooltip={true}
                        order="column"
                        value={item?.content_id ? translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : ''  : ''}
                        name={item?.content_id ? item?.content_id : "component_content_"+index}
                        characterLimit={250}
                        onChange={(e) => {
                            /*
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                           */

                           //update translation
                           updateTranslation(selectedLanguage.code, item?.content_id, e.target.value);
                        }}
                        label={"Content"}
                        placeholder={"Content here"}
                        error={""}
                        tooltipText={
                        "Content text"
                        }
                    />

                </div>
            )}

            {item?.type == 'hotspots' && (
              <div>  
               <br />
               <h4 className={classes.formHeading}>Modals</h4>

               <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.content_id ?  translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '' : ''}
                    name={item?.content_id ? item?.content_id : "component_content_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        /*
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        */

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.content_id, e.target.value);
                    }}
                    type="textarea"
                    label={"Intro Modal Content"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Content text for the hotspot intro modal. Supports HTML tags"
                    }
               />

               <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.button_label_id ? translations[selectedLanguage.code][item?.button_label_id] ? translations[selectedLanguage.code][item?.button_label_id] : '' : ''}
                    name={item?.button_label_id ? item?.button_label_id : "component_button_label_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        /*
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],button_label:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        */

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.button_label_id, e.target.value);
                    }}
                    label={"Intro Modal Button Label"}
                    placeholder={"Button Label"}
                    error={""}
                    tooltipText={
                    "This is the button label for the hotspot intro modal"
                    }
               />

                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.conclusion_title_id ? translations[selectedLanguage.code][item?.conclusion_title_id] ? translations[selectedLanguage.code][item?.conclusion_title_id] : '' : ''}
                    name={item?.conclusion_title_id ? item?.conclusion_title_id : "component_conclusion_title_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        /*
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],conclusion_title:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        */

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.conclusion_title_id, e.target.value);
                    }}
                    label={"Conclusion Title"}
                    placeholder={"Conclusion Title"}
                    error={""}
                    tooltipText={
                    "This is the conclusion modal title. Appears when all the hotspots are clicked."
                    }
               />

                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.conclusion_content_id ? translations[selectedLanguage.code][item?.conclusion_content_id] ? translations[selectedLanguage.code][item?.conclusion_content_id] : '' : ''}
                    name={item?.conclusion_content_id ? item?.conclusion_content_id : "component_conclusion_content_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        /*
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],conclusion_content:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        */

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.conclusion_content_id, e.target.value);
                    }}
                    type="textarea"
                    label={"Conclusion Content"}
                    placeholder={"Conclusion Content"}
                    error={""}
                    tooltipText={
                    "This is the conclusion modal content. Appears when all the hotspots are clicked. Supports HTML."
                    }
               />


                <ImageUpload
                    isSuccess={inductionImageSuccess}
                    isUploading={inductionImageLoading}
                    isError={inductionImageError}
                    onDelete={() => {
                    //setFieldValue("image_url", null);
                    //setChangeCount((val) => val + 1);
                    }}
                    imageUrl={item?.background}
                    description={"Upload a landscape sized photo from your device that represents the hotspots image. Don't worry, we've got the web-friendly optimization covered."}
                    onLoad={(event) => {
                    if (event.target.files[0]) {
                        
                        setInitialState(false);
                        setSelectedComponent(index);
                        uploadInductionImage({
                            image: event.target.files[0],
                        });
                        
                    }
                    }}
                />

               
               <br />
               <h4 className={classes.formHeading}>Hotspots</h4>

               {/* Hotspots */}
               {item?.hotspots.map((hotspotitem,hindex) =>
                    <>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={hotspotitem?.name_id ? translations[selectedLanguage.code][hotspotitem?.name_id] ? translations[selectedLanguage.code][hotspotitem?.name_id] : '' : ''}
                        name={hotspotitem?.name_id ? hotspotitem?.name_id : "component_hotspot_name_"+hindex}
                        characterLimit={250}
                        onChange={(e) => {
                            /*
                            //create new array of hotspots and update the selected one
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],name:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            */
                            
                            //update translation
                            updateTranslation(selectedLanguage.code, hotspotitem?.name_id, e.target.value);
                        }}
                        label={"Hotspot Name "+ (hindex + 1)}
                        placeholder={"Hotspot Name"}
                        error={""}
                        tooltipText={
                        "Hotspot name that is displayed when the hotspot icon is clicked."
                        }
                    />
                   <Input
                        hasTooltip={true}
                        order="column"
                        value={hotspotitem?.content_id ? translations[selectedLanguage.code][hotspotitem?.content_id] ? translations[selectedLanguage.code][hotspotitem?.content_id] : '' : ''}
                        name={hotspotitem?.content_id ? hotspotitem?.content_id : "component_hotspot_content_"+hindex}
                        type="textarea"
                        characterLimit={250}
                        onChange={(e) => {
                            /*
                            //create new array of hotspots and update the selected one
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],content:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            */
                            
                            //update translation
                            updateTranslation(selectedLanguage.code, hotspotitem?.content_id, e.target.value);
                        }}
                        label={"Hotspot Content "+ (hindex + 1)}
                        placeholder={"Hotspot Content"}
                        error={""}
                        tooltipText={
                        "Hotspot content that is displayed when the hotspot icon is clicked."
                        }
                   />
                   <br />
                   </>
               )}

             
              </div>
            )}

            {item?.type == 'carousel' && (
              <div>
              <br />
              <h4 className={classes.formHeading}>Carousel Slides</h4>

               {/* Carousel Content */}
               {item?.content.map((carouselItem,cIndex) =>
                <>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={carouselItem?.title_id ? translations[selectedLanguage.code][carouselItem?.title_id] ? translations[selectedLanguage.code][carouselItem?.title_id] : '' : ''}
                        name={carouselItem?.title_id ? carouselItem?.title_id : "component_carousel_name_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            
                            //create new array of hotspots and update the selected one
                            /*
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],name:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            */

                            //update translation
                            updateTranslation(selectedLanguage.code, carouselItem?.title_id, e.target.value);
                        }}
                        label={"Carousel Title "+ (cIndex + 1)}                        
                        placeholder={"Carousel Title"}
                        error={""}
                        tooltipText={
                        "Title for the carousel slide " + (cIndex + 1)
                        }
                    />

                    <Input
                        hasTooltip={true}
                        order="column"
                        value={carouselItem?.content_id ? translations[selectedLanguage.code][carouselItem?.content_id] ? translations[selectedLanguage.code][carouselItem?.content_id] : '' : ''}
                        name={carouselItem?.content_id ? carouselItem?.content_id : "component_carousel_content_"+cIndex}
                        characterLimit={250}
                        type="textarea"
                        onChange={(e) => {

                            //update translation
                            updateTranslation(selectedLanguage.code, carouselItem?.content_id, e.target.value);
                        }}
                        label={"Carousel Content "+ (cIndex + 1)}
                        placeholder={"Carousel Content"}
                        error={""}
                        tooltipText={
                        "Content for the carousel slide " + (cIndex + 1) + " Supports HTML."
                        }
                    />

                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={carouselItem?.image}
                        description={"Upload a photo for the carousel. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            setSelectedKey('image');
                            setSelectedCard(cIndex);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />

                    <br />
                    </>
               )}
              
              </div>
            )}



            {item?.type == 'flipcard' && (
              <div>
                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.content_id ? translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '' : ''}
                    name={"component_"+index+"_content"}
                    characterLimit={256}
                    type="textarea"
                    onChange={(e) => {
                         
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                           

                           //update translation
                           updateTranslation(selectedLanguage.code,item?.content_id,e.target.value);
                    }}
                    label={"Content"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Content for the component. Supports HTML"
                    }
              />
              <br />
              <h4 className={classes.formHeading}>Flipcards</h4>

               {/* Flipcard Content */}
               {item?.cards.map((flipcardItem,cIndex) =>
                <>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.title_id ? translations[selectedLanguage.code][flipcardItem?.title_id] ? translations[selectedLanguage.code][flipcardItem?.title_id] : '' : ''}
                        name={flipcardItem?.title_id ? flipcardItem?.title_id : "component_flipcard_title_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.title_id, e.target.value);
                        }}
                        label={"Card "+ (cIndex + 1) + " Title"}                        
                        placeholder={"Card Title"}
                        error={""}
                        tooltipText={
                        "Title for the card nr " + (cIndex + 1)
                        }
                    />

                    <h5 className={classes.formSubHeading}>Front</h5>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.front_title_id ? translations[selectedLanguage.code][flipcardItem?.front_title_id] ? translations[selectedLanguage.code][flipcardItem?.front_title_id] : '' : ''}
                        name={flipcardItem?.front_title_id ? flipcardItem?.front_title_id : "component_flipcard_front_title_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.front_title_id, e.target.value);
                        }}
                        label={"Title "}                        
                        placeholder={"Title"}
                        error={""}
                        tooltipText={
                        "Title for the flipcard for the front side " + (cIndex + 1)
                        }
                    />
                    
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.front_id ? translations[selectedLanguage.code][flipcardItem?.front_id] ? translations[selectedLanguage.code][flipcardItem?.front_id] : '' : ''}
                        name={flipcardItem?.front_id ? flipcardItem?.front_id : "component_flipcard_front_content_"+cIndex}
                        characterLimit={250}
                        type="textarea"
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.front_id, e.target.value);
                        }}
                        label={"Content "}                        
                        placeholder={"Content"}
                        error={""}
                        tooltipText={
                        "Content for the front side " + (cIndex + 1)
                        }
                    />
                    
                    {/*
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.front_image ? flipcardItem?.front_image  : ' '}
                        name={"component_flipcard_front_image_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //create new array of flipcards and update the selected one
                            
                            var newCardsUpdate = [ ...item?.cards ]
                            
                            for(var j = 0; j < newCardsUpdate.length; j++)
                            {
                                if(j == cIndex)
                                    newCardsUpdate[j] = {...newCardsUpdate[j],front_image:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],cards:newCardsUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            
                        }}
                        label={"Image URL "}                        
                        placeholder={"https://..."}
                        error={""}
                        tooltipText={
                        "Image url for the front of the card."
                        }
                    />
                    */}

                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={flipcardItem?.front_image}
                        description={"Upload a square photo for the front side of the card. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            setSelectedKey('front_image');
                            setSelectedCard(cIndex);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />


                    <h5 className={classes.formSubHeading}>Back</h5>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.back_title_id ? translations[selectedLanguage.code][flipcardItem?.back_title_id] ? translations[selectedLanguage.code][flipcardItem?.back_title_id] : '' : ''}
                        name={flipcardItem?.back_title_id ? flipcardItem?.back_title_id : "component_flipcard_back_title_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.back_title_id, e.target.value);
                        }}
                        label={"Title"}                        
                        placeholder={"Title"}
                        error={""}
                        tooltipText={
                        "Title for the flipcard for the back " + (cIndex + 1)
                        }
                    />
                    
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.back_id ? translations[selectedLanguage.code][flipcardItem?.back_id] ? translations[selectedLanguage.code][flipcardItem?.back_id] : '' : ''}
                        name={flipcardItem?.back_id ? flipcardItem?.back_id : "component_flipcard_back_content_"+cIndex}
                        characterLimit={250}
                        type="textarea"
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.back_id, e.target.value);
                        }}
                        label={"Content"}                        
                        placeholder={"Content"}
                        error={""}
                        tooltipText={
                        "Content for the back, card nr: " + (cIndex + 1)
                        }
                    />
                    
                    {/*
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.back_image ? flipcardItem?.back_image  : ' '}
                        name={"component_flipcard_back_image_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //create new array of flipcards and update the selected one
                            
                            var newCardsUpdate = [ ...item?.cards ]
                            
                            for(var j = 0; j < newCardsUpdate.length; j++)
                            {
                                if(j == cIndex)
                                    newCardsUpdate[j] = {...newCardsUpdate[j],back_image:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],cards:newCardsUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                        }}
                        label={"Image URL "}                        
                        placeholder={"https://..."}
                        error={""}
                        tooltipText={
                        "Image url for the back of the card."
                        }
                    />
                    */}
                    
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={flipcardItem?.back_image}
                        description={"Upload a square photo for the back side of the card. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            setSelectedKey('back_image');
                            setSelectedCard(cIndex);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />


                    <br />
                    </>
               )}
              
              </div>
            )}


            {item?.type == 'card_sort' && (
              <div>
                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.content_id ? translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '' : ''}
                    name={"component_"+index+"_content"}
                    characterLimit={256}
                    type="textarea"
                    onChange={(e) => {
                         
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                           

                           //update translation
                           updateTranslation(selectedLanguage.code,item?.content_id,e.target.value);
                    }}
                    label={"Content"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Content for the component. Supports HTML"
                    }
              />
              <br />
              <h4 className={classes.formHeading}>Cards</h4>

              {item?.cards.map((cardItem,cIndex) =>
                <>
                    <h5 className={classes.formSubHeading}>Card {(cIndex + 1)}</h5>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={cardItem?.name_id ? translations[selectedLanguage.code][cardItem?.name_id] ? translations[selectedLanguage.code][cardItem?.name_id] : '' : ''}
                        name={cardItem?.name_id ? cardItem?.name_id : "component_cardsort_name_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, cardItem?.name_id, e.target.value);
                        }}
                        label={"Name"}                        
                        placeholder={"Card Name"}
                        error={""}
                        tooltipText={
                        "Name for the card nr " + (cIndex + 1)
                        }
                    />

                    <Input
                        hasTooltip={true}
                        order="column"
                        value={cardItem?.content_id ? translations[selectedLanguage.code][cardItem?.content_id] ? translations[selectedLanguage.code][cardItem?.content_id] : '' : ''}
                        name={cardItem?.content_id ? cardItem?.content_id : "component_cardsort_content_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, cardItem?.content_id, e.target.value);
                        }}
                        label={"Content"}                        
                        placeholder={"Card Content"}
                        error={""}
                        tooltipText={
                        "Content for the card nr " + (cIndex + 1)
                        }
                    />
                    <br />
                </>
              )}
              </div>

            )}




            </>
        )}


  </div>;
};


const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    inductionImage: makeSelectInductionImage(),
    inductionImageLoading: makeSelectInductionImageLoading(),
    inductionImageSuccess: makeSelectInductionImageSuccess(),
    inductionImageError: makeSelectInductionImageError(),
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      uploadInductionImage: (val) => dispatch(uploadInductionImageReq(val)),
    };
  };
  

  export default connect(mapStateToProps, mapDispatchToProps)(ChaptersForm);