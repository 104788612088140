import React,{useState} from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

const RulesForm = ({
page,
updatePageData,
translations,
selectedLanguage,
updateTranslation

}) => {


const [rules, setRules] = useState(page?.data?.rules);

const updateRule = (item, value) => {
    /*
    var updatedRules = [...rules];
    updatedRules[item] = {...rules[item], content:value};
    setRules(updatedRules);
    updatePageData({key:'rules',value:updatedRules});
    */
    updateTranslation(selectedLanguage.code, rules[item].content_id, value);
}


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Rules List</h4>
        
        {rules.map((item,index) =>
                          
            <>           
            <Input
                hasTooltip={true}
                order="column"
                value={item?.content_id ? translations[selectedLanguage.code][item?.content_id] : ''}
                name={"rule_"+index}
                characterLimit={120}
                onChange={(e) => {
                    updateRule(index,e.target.value);
                }}
                label={"Rule "+(index+1)}
                placeholder={"rule here"}
                error={""}
                tooltipText={
                "This is a site rule"
                }
            />
            <br />
            </>
        )}


  </div>;
};

export default RulesForm;