import React from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

const InductionCompleteForm = ({
  page,
  updatePageData,
  updateTranslation,
  translations,
  selectedLanguage,
}) => {


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Motivational Text</h4>
        <Input
            hasTooltip={true}
            order="column"
            value={page?.data?.content_id ? translations[selectedLanguage.code][page?.data?.content_id] ? translations[selectedLanguage.code][page?.data?.content_id] : ''  : ''}
            name={page?.data?.content_id ? page?.data?.content_id : "page_content_"+page.id}
            characterLimit={120}
            onChange={(e) => {
                updatePageData({key:'content',value:e.target.value});
                updateTranslation(selectedLanguage.code,page?.data?.content_id,e.target.value);
            }}
            label={"Content"}
            placeholder={"Content here"}
            error={""}
            tooltipText={
            "This content appears after the tick icon. It's used as a motivational text."
            }
        />


  </div>;
};

export default InductionCompleteForm;