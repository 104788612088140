import React,{ useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ImageUpload from "../../../Hazards/components/imageUpload";
import Input from "../../../../../components/Supervisor/input";

import classes from "../editor.module.scss";

import {
    uploadInductionImageReq
  } from "store/supervisor/actions";
  
import {
    makeSelectInductionImage,
    makeSelectLoading,
    makeSelectInductionImageLoading,
    makeSelectInductionImageError,
    makeSelectInductionImageSuccess,
} from "store/supervisor/selector";

import SoundIcon from "../../../../Introductory/sound.svg";
import TimeIcon from "../../../../Introductory/time.svg";
import LocationIcon from "../../../../Introductory/location.svg";

const icons = {
  sound: SoundIcon,
  time: TimeIcon,
  location: LocationIcon,
};

const Introduction = ({
page,
inductionImage,
inductionImageLoading,
inductionImageSuccess,
inductionImageError,
uploadInductionImage,
updatePageData,
translations,
selectedLanguage,
updateTranslation
}) => {

  const [imageUrl, setImageUrl] = useState(page?.data?.image);
  const [headingText, setHeadingText] = useState(page?.data?.content);
  const [recommendationText1, setRecommendationText1] = useState(page?.data?.recommendations[0]?.text);
  const [recommendationText2, setRecommendationText2] = useState(page?.data?.recommendations[1]?.text);
  const [recommendationText3, setRecommendationText3] = useState(page?.data?.recommendations[2]?.text);
  const [recommendations, setRecommendations] = useState(page?.data?.recommendations);
  const [initialState, setInitialState] = useState(true);


  const updateRecommendation = (item, value) => {
      var updatedRecommendations = [...recommendations];
      updatedRecommendations[item] = {...recommendations[item], text:value};
      setRecommendations(updatedRecommendations);
      updatePageData({key:'recommendations',value:recommendations});
  }

  
  useEffect(() => {
    if(initialState)
       return;

    if (inductionImage?.url) {
       setImageUrl(inductionImage?.url);
       updatePageData({page_id: page?.id, image_id: inductionImage?.id, image: inductionImage?.url })
    }
  }, [inductionImage]);
  


  useEffect(() => {
    
    if (page?.data?.image) {
      setImageUrl(page?.data?.image);
    }

  }, []);

  return <div>

        <br />
        <h4 className={classes.formHeading}>Welcome Image</h4>
        <ImageUpload
            isSuccess={inductionImageSuccess}
            isUploading={inductionImageLoading}
            isError={inductionImageError}
            onDelete={() => {
            //setFieldValue("image_url", null);
            //setChangeCount((val) => val + 1);
            }}
            imageUrl={imageUrl}
            description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
            onLoad={(event) => {
            if (event.target.files[0]) {
                setInitialState(false);
                uploadInductionImage({
                  image: event.target.files[0],
                });
            }
            }}
        />
      
      <br />
      
      <h4 className={classes.formHeading}>Requirements & Recommendations</h4>

      <Input
          hasTooltip={true}
          order="column"
          value={page?.data['content_id'] ? translations[selectedLanguage.code][page?.data['content_id']] : ''}
          name={"content"}
          characterLimit={50}
          onChange={(e) => {
            //setHeadingText(e.target.value);
            //updatePageData({key: 'content',value: e.target.value});
            updateTranslation(selectedLanguage.code,page?.data['content_id'],e.target.value);
          }}
          label={"Recommendation Header"}
          placeholder={"Recommendation Header"}
          error={""}
          tooltipText={
            "Recommendation Header"
          }
      />

      <br />

      <div className={classes.formItemWrapper}>
         <div>
           <label className={classes.formLabel}>Icon</label>
            <img
              alt={`Icon`}
              className={classes.icon}
              src={icons[page?.data?.recommendations[0]?.icon]}
            />
         </div>
          

          <Input
              hasTooltip={true}
              order="column"
              value={recommendations[0]?.text_id ? translations[selectedLanguage.code][recommendations[0]?.text_id] : recommendations[0]?.text}
              name={"Recommendation Text"}
              characterLimit={50}
              onChange={(e) => {
                //updateRecommendation(0,e.target.value);
                updateTranslation(selectedLanguage.code, recommendations[0]?.text_id, e.target.value);
              }}
              label={"Recommendation Text"}
              placeholder={"Recommendation Text"}
              error={""}
              tooltipText={
                "Recommendation Text"
              }
          />
      </div>

      <div className={classes.formItemWrapper}>
         <div>
           <label className={classes.formLabel}>Icon</label>
            <img
              alt={`Icon`}
              className={classes.icon}
              src={icons[page?.data?.recommendations[1]?.icon]}
            />
         </div>
          

          <Input
              hasTooltip={true}
              order="column"
              value={recommendations[1]?.text_id ? translations[selectedLanguage.code][recommendations[1]?.text_id] : recommendations[1]?.text}
              name={"Recommendation Text"}
              characterLimit={50}
              onChange={(e) => {
                //updateRecommendation(1,e.target.value);
                updateTranslation(selectedLanguage.code, recommendations[1]?.text_id, e.target.value);
              }}
              label={"Recommendation Text"}
              placeholder={"Recommendation Text"}
              error={""}
              tooltipText={
                "Recommendation Text"
              }
          />
      </div>


    {page?.data?.recommendations[2]?.icon && (  
      <div className={classes.formItemWrapper}>
         <div>
           <label className={classes.formLabel}>Icon</label>
           
            <img
              alt={`Icon`}
              className={classes.icon}
              src={icons[page?.data?.recommendations[2]?.icon]}
            />
           
         </div>
          
          <Input
              hasTooltip={true}
              order="column"
              value={recommendations[2]?.text_id ? translations[selectedLanguage.code][recommendations[2]?.text_id] : recommendations[2]?.text}
              name={"Recommendation Text"}
              characterLimit={50}
              onChange={(e) => {
                //updateRecommendation(2,e.target.value);
                updateTranslation(selectedLanguage.code, recommendations[2]?.text_id, e.target.value);
              }}
              label={"Recommendation Text"}
              placeholder={"Recommendation Text"}
              error={""}
              tooltipText={
                "Recommendation Text"
              }
          />
      </div>
    )}



  </div>;
};


const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    inductionImage: makeSelectInductionImage(),
    inductionImageLoading: makeSelectInductionImageLoading(),
    inductionImageSuccess: makeSelectInductionImageSuccess(),
    inductionImageError: makeSelectInductionImageError(),
  });
  const mapDispatchToProps = (dispatch) => {
    return {
      uploadInductionImage: (val) => dispatch(uploadInductionImageReq(val)),
    };
  };
  

  export default connect(mapStateToProps, mapDispatchToProps)(Introduction);

