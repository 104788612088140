import React,{ useEffect } from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

import { connect } from "react-redux";

import {
    getAssesmentRequest
  } from "store/assesment/actions";
  
  import { createStructuredSelector } from "reselect";
  import {
    makeSelectAssesment,
    makeSelectLoading,
  } from "store/assesment/selector";

  import Select from "react-select";

  const isCorrectOptions = [{value:1,label:"Yes"},{value:0,label:"No"}]


const AssessmentForm = ({
  page,
  getAssesment,
  assesmentData,
  updatePageData,
  updateTranslation,
  translations,
  selectedLanguage,
}) => {

  useEffect(() => {
        getAssesment({ id: page?.data?.assessmentId });
  }, []);


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Questions</h4>

        {assesmentData?.data?.questions.map((questionItem,qIndex) =>
            <>
            <br />
                <h5 className={classes.formSubHeading}>Question {(qIndex + 1)}</h5>
                <Input
                    hasTooltip={true}
                    order="column"
                    value={translations[selectedLanguage.code]['dynamic.question.'+questionItem.id] ? translations[selectedLanguage.code]['dynamic.question.'+questionItem.id] : ''}
                    name={questionItem?.id ? questionItem?.id : "assessment_question_"+qIndex}
                    characterLimit={250}
                    onChange={(e) => {
                        updateTranslation(selectedLanguage.code,'dynamic.question.'+questionItem.id,e.target.value);                       
                    }}
                    label={"Question"}                        
                    placeholder={"Please type a question"}
                    error={""}
                    tooltipText={
                    "Question nr: " + (qIndex + 1)
                    }
                />
                <br />
                <h6 className={classes.formSubHeading}>Question {(qIndex + 1)} Options</h6>
                {questionItem?.options.map((optionItem,oIndex) =>
                <>
                  <Input
                    hasTooltip={true}
                    order="column"
                    value={translations[selectedLanguage.code]['dynamic.questionoption.'+optionItem.id] ? translations[selectedLanguage.code]['dynamic.questionoption.'+optionItem.id] : ''}
                    name={optionItem?.id ? optionItem?.id : "assessment_question_option_"+oIndex}
                    characterLimit={250}
                    onChange={(e) => {
                        updateTranslation(selectedLanguage.code,'dynamic.questionoption.'+optionItem.id,e.target.value);                       
                    }}
                    label={"Title"}                        
                    placeholder={"Please type some text that represents this option"}
                    error={""}
                    tooltipText={
                    "Option nr: " + (oIndex + 1) + " Please leave empty to show image only."
                    }
                  />
                  
                  <Input
                    hasTooltip={true}
                    order="column"
                    value={optionItem?.image ? optionItem?.image : ''}
                    name={"assessment_question_option_image_"+oIndex}
                    characterLimit={250}
                    onChange={(e) => {
                        //updateTranslation(selectedLanguage.code,'dynamic.questionoption.'+optionItem.id,e.target.value);                       
                    }}
                    label={"Question Option Image"}                        
                    placeholder={"Please type an option image url"}
                    error={""}
                    tooltipText={
                    "Option nr: " + (oIndex + 1)
                    }
                  />

                  <p>Is this option correct?</p>
                    <Select
                        styles={{
                          control: () => ({
                            width: "100%",
                            height: 44,
                            display: "flex",
                            background: "#f8f8f8",
                            paddingLeft: "10px",
                            color: "#40507e",
                            border: "1px solid #E0E0E0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }),
                        }}
                        placeholder={"Is the option correct?"}
                        onChange={(val) => {
                          
                          
                        }}
                        tabSelectsValue={selectedLanguage}
                        value={isCorrectOptions.filter(
                          (option) => option.value === optionItem?.is_correct
                        )}
                        options={isCorrectOptions}
                        isSearchable={false}
                    />
                  <br />

                  <hr />
                 </>
                )}
            </>
            )}


  </div>;
};

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    assesmentData: makeSelectAssesment(),
  });

  const mapDispatchToProps = (dispatch) => {
    return {
        getAssesment: (val) => dispatch(getAssesmentRequest(val)),
    };
  };
  

  export default connect(mapStateToProps, mapDispatchToProps)(AssessmentForm);