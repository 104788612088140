import React from "react";
import classes from "./textImageArray.module.scss";
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "../FormattedHTMLMessage";

const TextImageArray = ({
  header,
  headerId,
  title,
  titleId,
  content,
  contentId,
  image,
  isPreview
}) => {
  return (
    <div className={classes.Container}>
      {header && (
        <div className={classes.Header}>
          <FormattedMessage
            defaultMessage={isPreview ? '' : header}
            id={isPreview ? header : headerId ? headerId : header}
          />
        </div>
      )}
      {image && (
        <>
          <div className={classes.Title}>
            <FormattedMessage
              defaultMessage={title}
              id={isPreview ? title : titleId ? titleId : title}
            />
          </div>
          <div className={classes.Content}>
            <FormattedHTMLMessage
              id={isPreview ? content : contentId ? contentId : content ? content : "default"}
              value={content}
            />
          </div>
          <img src={image} className={classes.Image} alt={title} />
        </>
      )}
    </div>
  );
};

export default TextImageArray;
